import { yupResolver } from "@hookform/resolvers/yup";
import AddIcon from "@mui/icons-material/Add";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  CircularProgress,
  Grid,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { format } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { useOutletContext } from "react-router-dom";
import SnackbarContext from "../../../../contexts/SnackbarContextProvider";
import { productSchema } from "../../../../schemas/productSchema";
import { createProduct } from "../../../../services/admin/product/createProduct";
import { getProductCode } from "../../../../services/admin/product/getProductCode";
import { getProductCategory } from "../../../../services/admin/productCategory/getProductCategory";
import { getUnit } from "../../../../services/admin/unit/getUnit";
import { getWarehouse } from "../../../../services/admin/warehouse/getWarehouse";
import { generateSnackbarErrorMessage } from "../../../../utils/generateSnackbarErrorMessage";

const ProductCreate = () => {
  const { control, getValues, handleSubmit, reset, setValue } = useForm({
    defaultValues: {
      active_date: null,
      code: "",
      conversion_big: 0,
      conversion_medium: 0,
      conversion_small: 0,
      default_discount_percentage: 0,
      expiry_date: null,
      hpp: 0,
      inactive_date: null,
      initial_stock_big: 0,
      initial_stock_medium: 0,
      initial_stock_small: 0,
      inventory_value: 0,
      maximum_stock: 0,
      minimum_stock: 0,
      name: "",
      price_big: 0,
      price_medium: 0,
      price_small: 0,
      product_category: null,
      purchase_discount_percentage: 0,
      purchase_price: 0,
      sale_discount_percentage: 0,
      sale_price: 0,
      stock_as_of_date: 0,
      unit_big: null,
      unit_medium: null,
      unit_small: null,
      warehouse: null,
    },
    resolver: yupResolver(productSchema),
  });
  const [
    autocompleteProductCategoryOptions,
    setAutocompleteProductCategoryOptions,
  ] = useState([]);
  const [autocompleteUnitBigOptions, setAutocompleteUnitBigOptions] = useState(
    []
  );
  const [autocompleteUnitMediumOptions, setAutocompleteUnitMediumOptions] =
    useState([]);
  const [autocompleteUnitSmallOptions, setAutocompleteUnitSmallOptions] =
    useState([]);
  const [autocompleteWarehouseOptions, setAutocompleteWarehouseOptions] =
    useState([]);
  const [isLoadingButtonState, setIsLoadingButtonState] = useState({
    buttonCreate: false,
  });
  const [
    isLoadingAutocompleteProductCategory,
    setIsLoadingAutocompleteProductCategory,
  ] = useState(false);
  const [isLoadingAutocompleteUnitBig, setIsLoadingAutocompleteUnitBig] =
    useState(false);
  const [isLoadingAutocompleteUnitMedium, setIsLoadingAutocompleteUnitMedium] =
    useState(false);
  const [isLoadingAutocompleteUnitSmall, setIsLoadingAutocompleteUnitSmall] =
    useState(false);
  const [isLoadingAutocompleteWarehouse, setIsLoadingAutocompleteWarehouse] =
    useState(false);
  // eslint-disable-next-line
  const [isLoadingLinearProgress, setIsLoadingLinearProgress] =
    useOutletContext();
  const [
    isOpenAutocompleteProductCategory,
    setIsOpenAutocompleteProductCategory,
  ] = useState(false);
  const [isOpenAutocompleteUnitBig, setIsOpenAutocompleteUnitBig] =
    useState(false);
  const [isOpenAutocompleteUnitMedium, setIsOpenAutocompleteUnitMedium] =
    useState(false);
  const [isOpenAutocompleteUnitSmall, setIsOpenAutocompleteUnitSmall] =
    useState(false);
  const [isOpenAutocompleteWarehouse, setIsOpenAutocompleteWarehouse] =
    useState(false);
  const snackbarContext = useContext(SnackbarContext);

  const fetchProductCode = async () => {
    try {
      setIsLoadingLinearProgress(true);

      const res = await getProductCode({
        product_category_id: getValues("product_category").id,
      });

      setValue("code", res.payload);
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setIsLoadingLinearProgress(false);
    }
  };

  const handleCreateProduct = async (data) => {
    try {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonCreate: true,
      }));
      setIsLoadingLinearProgress(true);

      data.active_date = format(data.active_date, "yyyy-MM-dd HH:mm:ss");
      data.expiry_date = format(data.expiry_date, "yyyy-MM-dd HH:mm:ss");
      data.inactive_date = format(data.inactive_date, "yyyy-MM-dd HH:mm:ss");

      const res = await createProduct(data);

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 201) {
        reset();
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonCreate: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  // autocomplete product category
  useEffect(() => {
    setAutocompleteProductCategoryOptions([]);

    if (!isOpenAutocompleteProductCategory) {
      return undefined;
    }

    (async () => {
      try {
        setIsLoadingAutocompleteProductCategory(true);

        const res = await getProductCategory();

        if (res.status === 200) {
          setAutocompleteProductCategoryOptions(res.payload);
        }
      } catch (error) {
        snackbarContext.handleOpenSnackbar(
          generateSnackbarErrorMessage(error),
          "error"
        );
      } finally {
        setIsLoadingAutocompleteProductCategory(false);
      }
    })();

    return () => {};
    // eslint-disable-next-line
  }, [isOpenAutocompleteProductCategory]);

  // autocomplete unit big
  useEffect(() => {
    setAutocompleteUnitBigOptions([]);

    if (!isOpenAutocompleteUnitBig) {
      return undefined;
    }

    (async () => {
      try {
        setIsLoadingAutocompleteUnitBig(true);

        const res = await getUnit();

        if (res.status === 200) {
          setAutocompleteUnitBigOptions(res.payload);
        }
      } catch (error) {
        snackbarContext.handleOpenSnackbar(
          generateSnackbarErrorMessage(error),
          "error"
        );
      } finally {
        setIsLoadingAutocompleteUnitBig(false);
      }
    })();

    return () => {};
    // eslint-disable-next-line
  }, [isOpenAutocompleteUnitBig]);

  // autocomplete unit medium
  useEffect(() => {
    setAutocompleteUnitMediumOptions([]);

    if (!isOpenAutocompleteUnitMedium) {
      return undefined;
    }

    (async () => {
      try {
        setIsLoadingAutocompleteUnitMedium(true);

        const res = await getUnit();

        if (res.status === 200) {
          setAutocompleteUnitMediumOptions(res.payload);
        }
      } catch (error) {
        snackbarContext.handleOpenSnackbar(
          generateSnackbarErrorMessage(error),
          "error"
        );
      } finally {
        setIsLoadingAutocompleteUnitMedium(false);
      }
    })();

    return () => {};
    // eslint-disable-next-line
  }, [isOpenAutocompleteUnitMedium]);

  // autocomplete unit small
  useEffect(() => {
    setAutocompleteUnitSmallOptions([]);

    if (!isOpenAutocompleteUnitSmall) {
      return undefined;
    }

    (async () => {
      try {
        setIsLoadingAutocompleteUnitSmall(true);

        const res = await getUnit();

        if (res.status === 200) {
          setAutocompleteUnitSmallOptions(res.payload);
        }
      } catch (error) {
        snackbarContext.handleOpenSnackbar(
          generateSnackbarErrorMessage(error),
          "error"
        );
      } finally {
        setIsLoadingAutocompleteUnitSmall(false);
      }
    })();

    return () => {};
    // eslint-disable-next-line
  }, [isOpenAutocompleteUnitSmall]);

  // autocomplete warehouse
  useEffect(() => {
    setAutocompleteWarehouseOptions([]);

    if (!isOpenAutocompleteWarehouse) {
      return undefined;
    }

    (async () => {
      try {
        setIsLoadingAutocompleteWarehouse(true);

        const res = await getWarehouse();

        if (res.status === 200) {
          setAutocompleteWarehouseOptions(res.payload);
        }
      } catch (error) {
        snackbarContext.handleOpenSnackbar(
          generateSnackbarErrorMessage(error),
          "error"
        );
      } finally {
        setIsLoadingAutocompleteWarehouse(false);
      }
    })();

    return () => {};
    // eslint-disable-next-line
  }, [isOpenAutocompleteWarehouse]);

  return (
    <form onSubmit={handleSubmit(handleCreateProduct)}>
      <Grid container spacing={3}>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="active_date"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <DatePicker
                format="dd-MM-yyyy"
                inputRef={ref}
                label="Tanggal Aktif"
                onChange={onChange}
                slotProps={{
                  textField: {
                    error: !!error,
                    fullWidth: true,
                    helperText: error?.message,
                  },
                }}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="inactive_date"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <DatePicker
                format="dd-MM-yyyy"
                inputRef={ref}
                label="Tanggal nonaktif"
                onChange={onChange}
                slotProps={{
                  textField: {
                    error: !!error,
                    fullWidth: true,
                    helperText: error?.message,
                  },
                }}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="code"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                disabled
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Kode Barang"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="name"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Nama Barang"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="product_category"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <Autocomplete
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                loading={isLoadingAutocompleteProductCategory}
                onChange={(_, newValue) => {
                  onChange(newValue);

                  if (newValue) {
                    fetchProductCode();
                  } else {
                    setValue("code", "");
                  }
                }}
                onClose={() => {
                  setIsOpenAutocompleteProductCategory(false);
                }}
                onOpen={() => {
                  setIsOpenAutocompleteProductCategory(true);
                }}
                open={isOpenAutocompleteProductCategory}
                options={autocompleteProductCategoryOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!error}
                    helperText={error?.message}
                    inputRef={ref}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {isLoadingAutocompleteProductCategory ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                    label="Kategori Produk"
                  />
                )}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="expiry_date"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <DatePicker
                format="dd-MM-yyyy"
                inputRef={ref}
                label="Tanggal Kadaluarsa"
                onChange={onChange}
                slotProps={{
                  textField: {
                    error: !!error,
                    fullWidth: true,
                    helperText: error?.message,
                  },
                }}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="hpp"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <NumericFormat
                allowNegative={false}
                customInput={TextField}
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputProps={{ inputMode: "numeric" }}
                inputRef={ref}
                label="HPP"
                onValueChange={(values) => {
                  onChange(values.floatValue);
                }}
                thousandSeparator=","
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="unit_small"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <Autocomplete
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                loading={isLoadingAutocompleteUnitSmall}
                onChange={(_, newValue) => {
                  onChange(newValue);
                }}
                onClose={() => {
                  setIsOpenAutocompleteUnitSmall(false);
                }}
                onOpen={() => {
                  setIsOpenAutocompleteUnitSmall(true);
                }}
                open={isOpenAutocompleteUnitSmall}
                options={autocompleteUnitSmallOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!error}
                    helperText={error?.message}
                    inputRef={ref}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {isLoadingAutocompleteUnitSmall ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                    label="Satuan Kecil"
                  />
                )}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="price_small"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <NumericFormat
                allowNegative={false}
                customInput={TextField}
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputProps={{ inputMode: "numeric" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">Rp</InputAdornment>
                  ),
                }}
                inputRef={ref}
                label="Harga Satuan Kecil"
                onValueChange={(values) => {
                  onChange(values.floatValue);
                }}
                thousandSeparator=","
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="unit_medium"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <Autocomplete
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                loading={isLoadingAutocompleteUnitMedium}
                onChange={(_, newValue) => {
                  onChange(newValue);
                }}
                onClose={() => {
                  setIsOpenAutocompleteUnitMedium(false);
                }}
                onOpen={() => {
                  setIsOpenAutocompleteUnitMedium(true);
                }}
                open={isOpenAutocompleteUnitMedium}
                options={autocompleteUnitMediumOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!error}
                    helperText={error?.message}
                    inputRef={ref}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {isLoadingAutocompleteUnitMedium ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                    label="Satuan Menengah"
                  />
                )}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="price_medium"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <NumericFormat
                allowNegative={false}
                customInput={TextField}
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputProps={{ inputMode: "numeric" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">Rp</InputAdornment>
                  ),
                }}
                inputRef={ref}
                label="Harga Satuan Menengah"
                onValueChange={(values) => {
                  onChange(values.floatValue);
                }}
                thousandSeparator=","
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="unit_big"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <Autocomplete
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                loading={isLoadingAutocompleteUnitBig}
                onChange={(_, newValue) => {
                  onChange(newValue);
                }}
                onClose={() => {
                  setIsOpenAutocompleteUnitBig(false);
                }}
                onOpen={() => {
                  setIsOpenAutocompleteUnitBig(true);
                }}
                open={isOpenAutocompleteUnitBig}
                options={autocompleteUnitBigOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!error}
                    helperText={error?.message}
                    inputRef={ref}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {isLoadingAutocompleteUnitBig ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                    label="Satuan Besar"
                  />
                )}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="price_big"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <NumericFormat
                allowNegative={false}
                customInput={TextField}
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputProps={{ inputMode: "numeric" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">Rp</InputAdornment>
                  ),
                }}
                inputRef={ref}
                label="Harga Satuan Besar"
                onValueChange={(values) => {
                  onChange(values.floatValue);
                }}
                thousandSeparator=","
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="conversion_big"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <NumericFormat
                allowNegative={false}
                customInput={TextField}
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputProps={{ inputMode: "numeric" }}
                inputRef={ref}
                label="Konversi Besar"
                onValueChange={(values) => {
                  onChange(values.floatValue);
                }}
                thousandSeparator=","
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="conversion_medium"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <NumericFormat
                allowNegative={false}
                customInput={TextField}
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputProps={{ inputMode: "numeric" }}
                inputRef={ref}
                label="Konversi menengah"
                onValueChange={(values) => {
                  onChange(values.floatValue);
                }}
                thousandSeparator=","
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="conversion_small"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <NumericFormat
                allowNegative={false}
                customInput={TextField}
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputProps={{ inputMode: "numeric" }}
                inputRef={ref}
                label="Konversi Kecil"
                onValueChange={(values) => {
                  onChange(values.floatValue);
                }}
                thousandSeparator=","
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="default_discount_percentage"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <NumericFormat
                allowNegative={false}
                customInput={TextField}
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputProps={{ inputMode: "numeric" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
                inputRef={ref}
                isAllowed={(values) => {
                  const { formattedValue, floatValue } = values;
                  return formattedValue === "" || floatValue <= 100;
                }}
                label="Default Diskon"
                onValueChange={(values) => {
                  onChange(values.floatValue);
                }}
                thousandSeparator=","
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="purchase_price"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <NumericFormat
                allowNegative={false}
                customInput={TextField}
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputProps={{ inputMode: "numeric" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">Rp</InputAdornment>
                  ),
                }}
                inputRef={ref}
                label="Harga Beli"
                onValueChange={(values) => {
                  onChange(values.floatValue);
                }}
                thousandSeparator=","
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="sale_price"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <NumericFormat
                allowNegative={false}
                customInput={TextField}
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputProps={{ inputMode: "numeric" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">Rp</InputAdornment>
                  ),
                }}
                inputRef={ref}
                label="Harga Jual"
                onValueChange={(values) => {
                  onChange(values.floatValue);
                }}
                thousandSeparator=","
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="initial_stock_big"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <NumericFormat
                allowNegative={false}
                customInput={TextField}
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputProps={{ inputMode: "numeric" }}
                inputRef={ref}
                label="Stok Awal Besar"
                onValueChange={(values) => {
                  onChange(values.floatValue);
                }}
                thousandSeparator=","
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="initial_stock_medium"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <NumericFormat
                allowNegative={false}
                customInput={TextField}
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputProps={{ inputMode: "numeric" }}
                inputRef={ref}
                label="Stok Awal menengah"
                onValueChange={(values) => {
                  onChange(values.floatValue);
                }}
                thousandSeparator=","
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="initial_stock_small"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <NumericFormat
                allowNegative={false}
                customInput={TextField}
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputProps={{ inputMode: "numeric" }}
                inputRef={ref}
                label="Stok Awal Kecil"
                onValueChange={(values) => {
                  onChange(values.floatValue);
                }}
                thousandSeparator=","
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="maximum_stock"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <NumericFormat
                allowNegative={false}
                customInput={TextField}
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputProps={{ inputMode: "numeric" }}
                inputRef={ref}
                label="Stok Maksimum"
                onValueChange={(values) => {
                  onChange(values.floatValue);
                }}
                thousandSeparator=","
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="minimum_stock"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <NumericFormat
                allowNegative={false}
                customInput={TextField}
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputProps={{ inputMode: "numeric" }}
                inputRef={ref}
                label="Stok Minimum"
                onValueChange={(values) => {
                  onChange(values.floatValue);
                }}
                thousandSeparator=","
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="stock_as_of_date"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <NumericFormat
                allowNegative={false}
                customInput={TextField}
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputProps={{ inputMode: "numeric" }}
                inputRef={ref}
                label="Stok per tanggal"
                onValueChange={(values) => {
                  onChange(values.floatValue);
                }}
                thousandSeparator=","
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="inventory_value"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <NumericFormat
                allowNegative={false}
                customInput={TextField}
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputProps={{ inputMode: "numeric" }}
                inputRef={ref}
                label="Nilai Persediaan"
                onValueChange={(values) => {
                  onChange(values.floatValue);
                }}
                thousandSeparator=","
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="purchase_discount_percentage"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <NumericFormat
                allowNegative={false}
                customInput={TextField}
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputProps={{ inputMode: "numeric" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
                inputRef={ref}
                isAllowed={(values) => {
                  const { formattedValue, floatValue } = values;
                  return formattedValue === "" || floatValue <= 100;
                }}
                label="Persentase Diskon Beli"
                onValueChange={(values) => {
                  onChange(values.floatValue);
                }}
                thousandSeparator=","
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="sale_discount_percentage"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <NumericFormat
                allowNegative={false}
                customInput={TextField}
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputProps={{ inputMode: "numeric" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
                inputRef={ref}
                isAllowed={(values) => {
                  const { formattedValue, floatValue } = values;
                  return formattedValue === "" || floatValue <= 100;
                }}
                label="Persentase Diskon Jual"
                onValueChange={(values) => {
                  onChange(values.floatValue);
                }}
                thousandSeparator=","
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="warehouse"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <Autocomplete
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                loading={isLoadingAutocompleteWarehouse}
                onChange={(_, newValue) => {
                  onChange(newValue);
                }}
                onClose={() => {
                  setIsOpenAutocompleteWarehouse(false);
                }}
                onOpen={() => {
                  setIsOpenAutocompleteWarehouse(true);
                }}
                open={isOpenAutocompleteWarehouse}
                options={autocompleteWarehouseOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!error}
                    helperText={error?.message}
                    inputRef={ref}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {isLoadingAutocompleteWarehouse ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                    label="Gudang"
                  />
                )}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="flex-end">
            <LoadingButton
              endIcon={<AddIcon />}
              loading={isLoadingButtonState.buttonCreate}
              loadingPosition="end"
              type="submit"
              variant="contained"
            >
              Add
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
};

export default ProductCreate;
