export const getPurchaseOrderDetailColumns = () => [
  {
    field: "id",
    headerName: "ID",
    width: 80,
  },
  {
    field: "product_name",
    flex: 1,
    headerName: "Nama Barang",
    minWidth: 200,
  },
  {
    field: "product_code",
    flex: 1,
    headerName: "Kode Barang",
    minWidth: 200,
  },
  {
    field: "quantity",
    flex: 1,
    headerName: "Kuantitas",
    minWidth: 200,
    type: "number",
    valueFormatter: (value) => {
      return new Intl.NumberFormat("id-ID").format(value);
    },
  },
];
