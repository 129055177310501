import { yupResolver } from "@hookform/resolvers/yup";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { format, parse } from "date-fns";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useOutletContext, useParams } from "react-router-dom";
import AdminDataGridRow from "../../../../components/admin/AdminDataGridRow";
import { getBankCashAccountColumns } from "../../../../constants/columns/admin/bankCash/getBankCashAccountColumns";
import { transactionTypeOptions } from "../../../../constants/transactionTypeOptions";
import SnackbarContext from "../../../../contexts/SnackbarContextProvider";
import { bankCashSchema } from "../../../../schemas/bankCashSchema";
import { getBankCashById } from "../../../../services/admin/bankCash/getBankCashById";
import { getBankCashNumber } from "../../../../services/admin/bankCash/getBankCashNumber";
import { updateBankCash } from "../../../../services/admin/bankCash/updateBankCash";
import { getBankCashCategory } from "../../../../services/admin/bankCashCategory/getBankCashCategory";
import { getChartOfAccount } from "../../../../services/admin/chartOfAccount/getChartOfAccount";
import { getDivision } from "../../../../services/admin/division/getDivision";
import { getInvoiceNumber } from "../../../../services/admin/invoiceNumber/getInvoiceNumber";
import { generateSnackbarErrorMessage } from "../../../../utils/generateSnackbarErrorMessage";

const BankCashDetail = () => {
  const { id } = useParams();
  const { control, getValues, handleSubmit, setValue } = useForm({
    defaultValues: {
      bank_cash_category: null,
      date: null,
      division: null,
      fpd: null,
      invoice_number: null,
      transaction_type: null,
      voucher_number: "",
    },
    resolver: yupResolver(bankCashSchema),
  });
  const [
    autocompleteBankCashCategoryOptions,
    setAutocompleteBankCashCategoryOptions,
  ] = useState([]);
  const [autocompleteCoaOptions, setAutocompleteCoaOptions] = useState([]);
  const [autocompleteDivisionOptions, setAutocompleteDivisionOptions] =
    useState([]);
  const [
    autocompleteInvoiceNumberOptions,
    setAutocompleteInvoiceNumberOptions,
  ] = useState([]);
  const [
    isLoadingAutocompleteBankCashCategory,
    setIsLoadingAutocompleteBankCashCategory,
  ] = useState(false);
  const [isLoadingAutocompleteDivision, setIsLoadingAutocompleteDivision] =
    useState(false);
  const [
    isLoadingAutocompleteInvoiceNumber,
    setIsLoadingAutocompleteInvoiceNumber,
  ] = useState(false);
  const [isLoadingButtonState, setIsLoadingButtonState] = useState({
    buttonUpdate: false,
  });
  // eslint-disable-next-line
  const [isLoadingLinearProgress, setIsLoadingLinearProgress] =
    useOutletContext();
  const [
    isOpenAutocompleteBankCashCategory,
    setIsOpenAutocompleteBankCashCategory,
  ] = useState(false);
  const [isOpenAutocompleteDivision, setIsOpenAutocompleteDivision] =
    useState(false);
  const [isOpenAutocompleteInvoiceNumber, setIsOpenAutocompleteInvoiceNumber] =
    useState(false);
  const adminDataGridRowRef = useRef();
  const snackbarContext = useContext(SnackbarContext);

  const fetchBankCashById = async () => {
    try {
      setIsLoadingLinearProgress(true);

      const res = await getBankCashById(id);

      if (res.status === 200) {
        setValue("bank_cash_category", res.payload.bank_cash_category);
        setValue("date", parse(res.payload.date, "yyyy-MM-dd", new Date()));
        setValue("division", res.payload.division);
        setValue("voucher_number", res.payload.voucher_number);
        setValue(
          "transaction_type",
          transactionTypeOptions.find(
            (element) => element.id === res.payload.transaction_type
          )
        );

        let tempBankCashDetail = [];

        for (let i = 0; i < res.payload.bank_cash_detail.length; i++) {
          const element = res.payload.bank_cash_detail[i];

          tempBankCashDetail.push({
            id: element.id,
            amount: element.amount,
            coa_id: element.coa_id,
            description: element.description,
            isNew: false,
          });
        }

        adminDataGridRowRef.current.setRows(tempBankCashDetail);
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setIsLoadingLinearProgress(false);
    }
  };

  const fetchBankCashNumber = async () => {
    try {
      setIsLoadingLinearProgress(true);

      const res = await getBankCashNumber({
        bank_cash_category_id: getValues("bank_cash_category").id,
        date: format(getValues("date"), "yyyy-MM-dd HH:mm:ss"),
      });

      setValue("voucher_number", res.payload);
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setIsLoadingLinearProgress(false);
    }
  };

  const handleUpdateBankCash = async (data) => {
    try {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonUpdate: true,
      }));
      setIsLoadingLinearProgress(true);

      data.id = parseInt(id);
      data.date = format(data.date, "yyyy-MM-dd HH:mm:ss");
      data.bank_cash_detail = adminDataGridRowRef.current.rows;
      data.purchase_invoice_number = "";
      data.sales_invoice_number = "";
      data.transaction_type = data.transaction_type.id;

      if (data.invoice_number?.invoice_type === 1) {
        data.purchase_invoice_number = data.invoice_number.invoice_number;
      } else if (data.invoice_number?.invoice_type === 2) {
        data.sales_invoice_number = data.invoice_number.invoice_number;
      }

      const res = await updateBankCash(data);

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 200) {
        fetchBankCashById();
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonUpdate: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  useEffect(() => {
    fetchBankCashById();

    return () => {};
    // eslint-disable-next-line
  }, []);

  // autocomplete coa
  useEffect(() => {
    setAutocompleteCoaOptions([]);

    (async () => {
      try {
        setIsLoadingLinearProgress(true);

        const res = await getChartOfAccount();

        if (res.status === 200) {
          setAutocompleteCoaOptions(res.payload);
        }
      } catch (error) {
        snackbarContext.handleOpenSnackbar(
          generateSnackbarErrorMessage(error),
          "error"
        );
      } finally {
        setIsLoadingLinearProgress(false);
      }
    })();

    return () => {};
    // eslint-disable-next-line
  }, []);

  // autocomplete bank cash category
  useEffect(() => {
    setAutocompleteBankCashCategoryOptions([]);

    if (!isOpenAutocompleteBankCashCategory) {
      return undefined;
    }

    (async () => {
      try {
        setIsLoadingAutocompleteBankCashCategory(true);

        const res = await getBankCashCategory();

        if (res.status === 200) {
          setAutocompleteBankCashCategoryOptions(res.payload);
        }
      } catch (error) {
        snackbarContext.handleOpenSnackbar(
          generateSnackbarErrorMessage(error),
          "error"
        );
      } finally {
        setIsLoadingAutocompleteBankCashCategory(false);
      }
    })();

    return () => {};
    // eslint-disable-next-line
  }, [isOpenAutocompleteBankCashCategory]);

  // autocomplete division
  useEffect(() => {
    setAutocompleteDivisionOptions([]);

    if (!isOpenAutocompleteDivision) {
      return undefined;
    }

    (async () => {
      try {
        setIsLoadingAutocompleteDivision(true);

        const res = await getDivision();

        if (res.status === 200) {
          setAutocompleteDivisionOptions(res.payload);
        }
      } catch (error) {
        snackbarContext.handleOpenSnackbar(
          generateSnackbarErrorMessage(error),
          "error"
        );
      } finally {
        setIsLoadingAutocompleteDivision(false);
      }
    })();

    return () => {};
    // eslint-disable-next-line
  }, [isOpenAutocompleteDivision]);

  // autocomplete invoice number
  useEffect(() => {
    setAutocompleteInvoiceNumberOptions([]);

    if (!isOpenAutocompleteInvoiceNumber) {
      return undefined;
    }

    (async () => {
      try {
        setIsLoadingAutocompleteInvoiceNumber(true);

        const res = await getInvoiceNumber();

        if (res.status === 200) {
          let temp = res.payload;

          for (let i = 0; i < res.payload.length; i++) {
            temp[i].id = i;
          }

          setAutocompleteInvoiceNumberOptions(temp);
        }
      } catch (error) {
        snackbarContext.handleOpenSnackbar(
          generateSnackbarErrorMessage(error),
          "error"
        );
      } finally {
        setIsLoadingAutocompleteInvoiceNumber(false);
      }
    })();

    return () => {};
    // eslint-disable-next-line
  }, [isOpenAutocompleteInvoiceNumber]);

  return (
    <Paper sx={{ p: 3 }}>
      <form onSubmit={handleSubmit(handleUpdateBankCash)}>
        <Grid container spacing={3}>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="voucher_number"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <TextField
                  disabled
                  error={!!error}
                  fullWidth
                  helperText={error?.message}
                  inputRef={ref}
                  label="Nomor Voucher"
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="bank_cash_category"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <Autocomplete
                  disabled
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  loading={isLoadingAutocompleteBankCashCategory}
                  onChange={(_, newValue) => {
                    onChange(newValue);

                    if (newValue && getValues("date")) {
                      fetchBankCashNumber();
                    } else {
                      setValue("voucher_number", "");
                    }
                  }}
                  onClose={() => {
                    setIsOpenAutocompleteBankCashCategory(false);
                  }}
                  onOpen={() => {
                    setIsOpenAutocompleteBankCashCategory(true);
                  }}
                  open={isOpenAutocompleteBankCashCategory}
                  options={autocompleteBankCashCategoryOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!error}
                      helperText={error?.message}
                      inputRef={ref}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {isLoadingAutocompleteBankCashCategory ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                      label="Kategori Kas Bank"
                    />
                  )}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="date"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <DatePicker
                  format="dd-MM-yyyy"
                  inputRef={ref}
                  label="Tanggal"
                  onChange={(newValue) => {
                    onChange(newValue);

                    if (getValues("bank_cash_category") && newValue) {
                      fetchBankCashNumber();
                    } else {
                      setValue("code", "");
                    }
                  }}
                  slotProps={{
                    textField: {
                      error: !!error,
                      fullWidth: true,
                      helperText: error?.message,
                    },
                  }}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="division"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <Autocomplete
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  loading={isLoadingAutocompleteDivision}
                  onChange={(_, newValue) => {
                    onChange(newValue);
                  }}
                  onClose={() => {
                    setIsOpenAutocompleteDivision(false);
                  }}
                  onOpen={() => {
                    setIsOpenAutocompleteDivision(true);
                  }}
                  open={isOpenAutocompleteDivision}
                  options={autocompleteDivisionOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!error}
                      helperText={error?.message}
                      inputRef={ref}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {isLoadingAutocompleteDivision ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                      label="Divisi"
                    />
                  )}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="transaction_type"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <Autocomplete
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(_, newValue) => {
                    onChange(newValue);
                  }}
                  options={transactionTypeOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!error}
                      helperText={error?.message}
                      inputRef={ref}
                      label="Jenis Transaksi"
                    />
                  )}
                  value={value}
                />
              )}
            />
          </Grid>
          {/* <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="invoice_number"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <Autocomplete
                  getOptionLabel={(option) => option.invoice_number}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  loading={isLoadingAutocompleteInvoiceNumber}
                  onChange={(_, newValue) => {
                    onChange(newValue);
                  }}
                  onClose={() => {
                    setIsOpenAutocompleteInvoiceNumber(false);
                  }}
                  onOpen={() => {
                    setIsOpenAutocompleteInvoiceNumber(true);
                  }}
                  open={isOpenAutocompleteInvoiceNumber}
                  options={autocompleteInvoiceNumberOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!error}
                      helperText={error?.message}
                      inputRef={ref}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {isLoadingAutocompleteInvoiceNumber ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                      label="Nomor Invoice"
                    />
                  )}
                  value={value}
                />
              )}
            />
          </Grid> */}
          <Grid item xs={12}>
            <div
              onKeyDown={(e) => {
                e.key === "Enter" && e.preventDefault();
              }}
            >
              <AdminDataGridRow
                columns={getBankCashAccountColumns}
                ref={adminDataGridRowRef}
                props={{ coa_code: autocompleteCoaOptions }}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-end">
              <LoadingButton
                endIcon={<SaveIcon />}
                loading={isLoadingButtonState.buttonUpdate}
                loadingPosition="end"
                type="submit"
                variant="contained"
              >
                Save
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default BankCashDetail;
