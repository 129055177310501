export const getCalculateHppDetailColumns = () => [
  {
    field: "id",
    headerName: "id",
    width: 80,
  },
  {
    field: "no",
    headerName: "No",
    width: 200,
  },
  {
    field: "code",
    headerName: "Kode Material",
    width: 200,
  },
  {
    field: "name",
    headerName: "Nama Material",
    width: 200,
  },
  {
    field: "weight",
    headerName: "Berat",
    width: 200,
    valueGetter: (_, row) => {
      return row.yield ? `${row.yield} Kg` : "";
    },
  },
  {
    field: "composition",
    headerName: "Komposisi",
    width: 200,
  },
  {
    field: "qty",
    headerName: "Qty (%)",
    width: 200,
  },
  {
    field: "price",
    headerName: "Harga",
    width: 200,
    align: "right",
    valueFormatter: (value) => {
      return new Intl.NumberFormat("id-ID", {
        currency: "IDR",
        style: "currency",
      }).format(value);
    },
  },
  {
    field: "purchase1",
    headerName: "Purchase price+Buffer 7%",
    width: 200,
    align: "right",
    valueFormatter: (value) => {
      return new Intl.NumberFormat("id-ID", {
        currency: "IDR",
        style: "currency",
      }).format(value);
    },
  },
  {
    field: "purchase2",
    headerName: "Purchase price+Buffer 7%+PPN",
    width: 200,
    align: "right",
    valueFormatter: (value) => {
      return new Intl.NumberFormat("id-ID", {
        currency: "IDR",
        style: "currency",
      }).format(value);
    },
  },
  {
    field: "purchase3",
    headerName: "Price + Buffer 7%  belum PPN",
    width: 200,
    align: "right",
    valueFormatter: (value) => {
      return new Intl.NumberFormat("id-ID", {
        currency: "IDR",
        style: "currency",
      }).format(value);
    },
  },
];
