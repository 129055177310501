import * as yup from "yup";

export const warehouseSchema = yup.object().shape({
  active_date: yup.date().required("Mohon mengisi tanggal aktif."),
  address: yup.string().required("Mohon mengisi Alamat Gudang."),
  code: yup.string().required("Mohon mengisi kode."),
  inactive_date: yup.date().notRequired(),
  name: yup.string().required("Mohon mengisi nama."),
  phone_number: yup.string().required("Mohon mengisi nomor telepon."),
  pic: yup.string().required("Mohon mengisi PIC."),
});
