import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { getManageUserColumns } from "../../../../constants/columns/admin/getManageUserColumns";
import DialogContext from "../../../../contexts/DialogContextProvider";
import SnackbarContext from "../../../../contexts/SnackbarContextProvider";
import { deleteUser } from "../../../../services/admin/manageUser/deleteUser";
import { getUser } from "../../../../services/admin/manageUser/getUser";
import { generateSnackbarErrorMessage } from "../../../../utils/generateSnackbarErrorMessage";
import AdminDataGridPro from "../../../../components/admin/AdminDataGridPro/AdminDataGridPro";

const ManageUserList = () => {
  const [dataGridState, setDataGridState] = useState({
    isLoading: false,
    rows: [],
  });
  // eslint-disable-next-line
  const [isLoadingLinearProgress, setIsLoadingLinearProgress] =
    useOutletContext();
  const dialogContext = useContext(DialogContext);
  const navigate = useNavigate();
  const snackbarContext = useContext(SnackbarContext);

  const fetchManageUser = async () => {
    try {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      setIsLoadingLinearProgress(true);

      const res = await getUser();

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 200) {
        setDataGridState((prevState) => ({
          ...prevState,
          rows: res.payload,
        }));
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  const handleDeleteManageUser = async (id) => {
    try {
      setIsLoadingLinearProgress(true);

      const res = await deleteUser(id);

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 200) {
        fetchManageUser();
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setIsLoadingLinearProgress(false);
    }
  };

  const handleUpdateManageUser = (id) => {
    navigate(`/admin/manage_user/${id}`);
  };

  useEffect(() => {
    fetchManageUser();

    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <AdminDataGridPro
      columns={getManageUserColumns(
        dialogContext,
        handleDeleteManageUser,
        handleUpdateManageUser
      )}
      dataGridState={dataGridState}
      fetchData={fetchManageUser}
    />
  );
};

export default ManageUserList;
