import { Dialog, DialogContent, DialogTitle, Divider } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import AdminDataGridPro from "../../../../components/admin/AdminDataGridPro/AdminDataGridPro";
import { getPurchaseOrderColumns } from "../../../../constants/columns/admin/purchaseOrder/getPurchaseOrderColumns";
import { getPurchaseOrderDetailColumns } from "../../../../constants/columns/admin/purchaseOrder/getPurchaseOrderDetailColumns";
import DialogContext from "../../../../contexts/DialogContextProvider";
import SnackbarContext from "../../../../contexts/SnackbarContextProvider";
import { deletePurchaseOrder } from "../../../../services/admin/purchaseOrder/deletePurchaseOrder";
import { getPurchaseOrder } from "../../../../services/admin/purchaseOrder/getPurchaseOrder";
import { generateSnackbarErrorMessage } from "../../../../utils/generateSnackbarErrorMessage";

const PurchaseOrderList = () => {
  const [dataGridDetailState, setDataGridDetailState] = useState({
    isLoading: false,
    rows: [],
  });
  const [dataGridState, setDataGridState] = useState({
    isLoading: false,
    rows: [],
  });
  // eslint-disable-next-line
  const [isLoadingLinearProgress, setIsLoadingLinearProgress] =
    useOutletContext();
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const dialogContext = useContext(DialogContext);
  const navigate = useNavigate();
  const snackbarContext = useContext(SnackbarContext);

  const fetchPurchaseOrder = async () => {
    try {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      setIsLoadingLinearProgress(true);

      const res = await getPurchaseOrder();

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 200) {
        setDataGridState((prevState) => ({
          ...prevState,
          rows: res.payload,
        }));
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  const handleCloseDialog = () => {
    setIsOpenDialog(false);
  };

  const handleDeletePurchaseOrder = async (id) => {
    try {
      setIsLoadingLinearProgress(true);

      const res = await deletePurchaseOrder(id);

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 200) {
        fetchPurchaseOrder();
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setIsLoadingLinearProgress(false);
    }
  };

  const handleExportPurchaseOrderPdf = async (id, number) => {
    try {
      setIsLoadingLinearProgress(true);

      // await exportPurchaseOrder(id, number);
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setIsLoadingLinearProgress(false);
    }
  };

  const handleGetPurchaseOrderDetails = (purchase_order_detail) => {
    setDataGridDetailState((prevState) => ({
      ...prevState,
      rows: purchase_order_detail,
    }));

    handleOpenDialog();
  };

  const handleOpenDialog = () => {
    setIsOpenDialog(true);
  };

  const handleUpdatePurchaseOrder = (id) => {
    navigate(`/admin/purchase_order/${id}`);
  };

  useEffect(() => {
    fetchPurchaseOrder();

    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Dialog
        fullWidth
        keepMounted
        maxWidth="md"
        onClose={handleCloseDialog}
        open={isOpenDialog}
      >
        <DialogTitle>Detail FPD</DialogTitle>
        <Divider />
        <DialogContent>
          <AdminDataGridPro
            columns={getPurchaseOrderDetailColumns()}
            dataGridState={dataGridDetailState}
          />
        </DialogContent>
      </Dialog>
      <AdminDataGridPro
        columns={getPurchaseOrderColumns(
          dialogContext,
          handleDeletePurchaseOrder,
          handleExportPurchaseOrderPdf,
          handleGetPurchaseOrderDetails,
          handleUpdatePurchaseOrder
        )}
        dataGridState={dataGridState}
      />
    </>
  );
};

export default PurchaseOrderList;
