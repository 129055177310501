import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { format } from "date-fns";

export const getPartnerColumns = (
  dialogContext,
  handleDeletePartner,
  handleUpdatePartner
) => [
  {
    field: "id",
    headerName: "ID",
    width: 80,
  },
  {
    field: "active_date",
    flex: 1,
    headerName: "Tanggal Aktif",
    minWidth: 200,
    type: "date",
    valueFormatter: (value) => {
      return value ? format(value, "dd-MM-yyyy") : null;
    },
  },
  {
    field: "balance_as_of_date",
    flex: 1,
    headerName: "Saldo per Tanggal",
    minWidth: 200,
    type: "number",
    valueFormatter: (value) => {
      return new Intl.NumberFormat("id-ID", {
        currency: "IDR",
        style: "currency",
      }).format(value);
    },
  },
  {
    field: "balance_limit",
    flex: 1,
    headerName: "Limit Saldo",
    minWidth: 200,
    type: "number",
    valueFormatter: (value) => {
      return new Intl.NumberFormat("id-ID", {
        currency: "IDR",
        style: "currency",
      }).format(value);
    },
  },
  {
    field: "bank_account_name",
    flex: 1,
    headerName: "Nama Rekening",
    minWidth: 200,
  },
  {
    field: "bank_account_number",
    flex: 1,
    headerName: "Nomor Rekening",
    minWidth: 200,
  },
  {
    field: "bank_name",
    flex: 1,
    headerName: "Nama Bank",
    minWidth: 200,
  },
  {
    field: "code",
    flex: 1,
    headerName: "Kode",
    minWidth: 200,
  },
  {
    field: "discount_percentage",
    flex: 1,
    headerName: "Persentase Diskon (%)",
    minWidth: 200,
    type: "number",
    valueFormatter: (value) => {
      return new Intl.NumberFormat("id-ID").format(value);
    },
  },
  {
    field: "email",
    flex: 1,
    headerName: "Email",
    minWidth: 200,
  },
  {
    field: "facsimile",
    flex: 1,
    headerName: "Fax",
    minWidth: 200,
  },
  {
    field: "inactive_date",
    flex: 1,
    headerName: "Tanggal Nonaktif",
    minWidth: 200,
    type: "date",
    valueFormatter: (value) => {
      return value ? format(value, "dd-MM-yyyy") : null;
    },
  },
  {
    field: "initial_balance",
    flex: 1,
    headerName: "Saldo Awal",
    minWidth: 200,
    type: "number",
    valueFormatter: (value) => {
      return new Intl.NumberFormat("id-ID", {
        currency: "IDR",
        style: "currency",
      }).format(value);
    },
  },
  {
    field: "name",
    flex: 1,
    headerName: "Nama Rekanan",
    minWidth: 200,
  },
  {
    field: "nib",
    flex: 1,
    headerName: "NIB",
    minWidth: 200,
  },
  {
    field: "nik",
    flex: 1,
    headerName: "NIK",
    minWidth: 200,
  },
  {
    field: "npwp",
    flex: 1,
    headerName: "NPWP",
    minWidth: 200,
  },
  {
    field: "npwp_address",
    flex: 1,
    headerName: "Alamat NPWP",
    minWidth: 200,
  },
  {
    field: "npwp_name",
    flex: 1,
    headerName: "Nama NPWP",
    minWidth: 200,
  },
  {
    field: "office_address",
    flex: 1,
    headerName: "Alamat Kantor",
    minWidth: 200,
  },
  {
    field: "partner_category_name",
    flex: 1,
    headerName: "Tipe Rekanan",
    minWidth: 200,
    valueGetter: (_, row) => {
      return row?.partner_category?.name ?? "";
    },
  },
  {
    field: "phone_number",
    flex: 1,
    headerName: "Nomor Telepon",
    minWidth: 200,
  },
  {
    field: "pic",
    flex: 1,
    headerName: "Orang yang bertanggung jawab",
    minWidth: 200,
  },
  {
    field: "skb",
    flex: 1,
    headerName: "SKB",
    minWidth: 200,
  },
  {
    field: "sppkp",
    flex: 1,
    headerName: "SPPKP",
    minWidth: 200,
  },
  {
    field: "sppkp_date",
    flex: 1,
    headerName: "Tanggal SPPKP",
    minWidth: 200,
    type: "date",
    valueFormatter: (value) => {
      return value ? format(value, "dd-MM-yyyy") : null;
    },
  },
  {
    field: "suket",
    flex: 1,
    headerName: "Suket",
    minWidth: 200,
  },
  {
    field: "suket_active_date",
    flex: 1,
    headerName: "Tanggal Aktif Suket",
    minWidth: 200,
    type: "date",
    valueFormatter: (value) => {
      return value ? format(value, "dd-MM-yyyy") : null;
    },
  },
  {
    field: "suket_inactive_date",
    flex: 1,
    headerName: "Tanggal Nonaktif Suket",
    minWidth: 200,
    type: "date",
    valueFormatter: (value) => {
      return value ? format(value, "dd-MM-yyyy") : null;
    },
  },
  {
    field: "top",
    flex: 1,
    headerName: "TOP",
    minWidth: 200,
  },
  {
    field: "warehouse_address",
    flex: 1,
    headerName: "Alamat Gudang",
    minWidth: 200,
  },
  {
    field: "actions",
    headerName: "Aksi",
    minWidth: 150,
    type: "actions",
    getActions: (params) => [
      <GridActionsCellItem
        icon={<EditIcon color="info" />}
        label="Edit"
        onClick={() => {
          handleUpdatePartner(params.row.id);
        }}
      />,
      <GridActionsCellItem
        icon={<DeleteIcon color="error" />}
        label="Delete"
        onClick={() => {
          dialogContext.handleOpenDialog(
            () => handleDeletePartner(params.id),
            "Are you sure want to delete?",
            "Delete Confirmation"
          );
        }}
      />,
    ],
  },
];
