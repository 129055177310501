import { yupResolver } from "@hookform/resolvers/yup";
import AddIcon from "@mui/icons-material/Add";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  CircularProgress,
  Grid,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { format } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { useOutletContext } from "react-router-dom";
import SnackbarContext from "../../../../contexts/SnackbarContextProvider";
import { partnerSchema } from "../../../../schemas/partnerSchema";
import { createPartner } from "../../../../services/admin/partner/createPartner";
import { getPartnerCode } from "../../../../services/admin/partner/getPartnerCode";
import { getPartnerCategory } from "../../../../services/admin/partnerCategory/getPartnerCategory";
import { generateSnackbarErrorMessage } from "../../../../utils/generateSnackbarErrorMessage";

const PartnerCreate = () => {
  const { control, getValues, handleSubmit, reset, setValue } = useForm({
    defaultValues: {
      active_date: null,
      balance_as_of_date: 0,
      balance_limit: 0,
      bank_account_name: "",
      bank_account_number: "",
      bank_name: "",
      code: "",
      discount_percentage: 0,
      email: "",
      facsimile: "",
      inactive_date: null,
      initial_balance: 0,
      name: "",
      nib: "",
      nik: "",
      npwp: "",
      npwp_address: "",
      npwp_name: "",
      office_address: "",
      partner_category: null,
      phone_number: "",
      pic: "",
      skb: "",
      sppkp: "",
      sppkp_date: null,
      suket: "",
      suket_active_date: null,
      suket_inactive_date: null,
      top: "",
      warehouse_address: "",
    },
    resolver: yupResolver(partnerSchema),
  });
  const [
    autocompletePartnerCategoryOptions,
    setAutocompletePartnerCategoryOptions,
  ] = useState([]);
  const [
    isLoadingAutocompletePartnerCategory,
    setIsLoadingAutocompletePartnerCategory,
  ] = useState(false);
  const [isLoadingButtonState, setIsLoadingButtonState] = useState({
    buttonCreate: false,
  });
  // eslint-disable-next-line
  const [isLoadingLinearProgress, setIsLoadingLinearProgress] =
    useOutletContext();
  const [
    isOpenAutocompletePartnerCategory,
    setIsOpenAutocompletePartnerCategory,
  ] = useState(false);
  const snackbarContext = useContext(SnackbarContext);

  const fetchPartnerCode = async () => {
    try {
      setIsLoadingLinearProgress(true);

      const res = await getPartnerCode({
        partner_category_id: getValues("partner_category").id,
      });

      setValue("code", res.payload);
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setIsLoadingLinearProgress(false);
    }
  };

  const handleCreatePartner = async (data) => {
    try {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonCreate: true,
      }));
      setIsLoadingLinearProgress(true);

      data.active_date = format(data.active_date, "yyyy-MM-dd HH:mm:ss");
      data.inactive_date = format(data.inactive_date, "yyyy-MM-dd HH:mm:ss");
      data.sppkp_date = format(data.sppkp_date, "yyyy-MM-dd HH:mm:ss");
      data.suket_active_date = format(
        data.suket_active_date,
        "yyyy-MM-dd HH:mm:ss"
      );
      data.suket_inactive_date = format(
        data.suket_inactive_date,
        "yyyy-MM-dd HH:mm:ss"
      );

      const res = await createPartner(data);

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 201) {
        reset();
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonCreate: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  // autocomplete partner category
  useEffect(() => {
    setAutocompletePartnerCategoryOptions([]);

    if (!isOpenAutocompletePartnerCategory) {
      return undefined;
    }

    (async () => {
      try {
        setIsLoadingAutocompletePartnerCategory(true);

        const res = await getPartnerCategory();

        if (res.status === 200) {
          setAutocompletePartnerCategoryOptions(res.payload);
        }
      } catch (error) {
        snackbarContext.handleOpenSnackbar(
          generateSnackbarErrorMessage(error),
          "error"
        );
      } finally {
        setIsLoadingAutocompletePartnerCategory(false);
      }
    })();

    return () => {};
    // eslint-disable-next-line
  }, [isOpenAutocompletePartnerCategory]);

  return (
    <form onSubmit={handleSubmit(handleCreatePartner)}>
      <Grid container spacing={3}>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="active_date"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <DatePicker
                format="dd-MM-yyyy"
                inputRef={ref}
                label="Tanggal Aktif"
                onChange={onChange}
                slotProps={{
                  textField: {
                    error: !!error,
                    fullWidth: true,
                    helperText: error?.message,
                  },
                }}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="balance_as_of_date"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <NumericFormat
                allowNegative={false}
                customInput={TextField}
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputProps={{ inputMode: "numeric" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">Rp</InputAdornment>
                  ),
                }}
                inputRef={ref}
                label="Saldo per tanggal"
                onValueChange={(values) => {
                  onChange(values.floatValue);
                }}
                thousandSeparator=","
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="balance_limit"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <NumericFormat
                allowNegative={false}
                customInput={TextField}
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputProps={{ inputMode: "numeric" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">Rp</InputAdornment>
                  ),
                }}
                inputRef={ref}
                label="Limit Saldo"
                onValueChange={(values) => {
                  onChange(values.floatValue);
                }}
                thousandSeparator=","
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="bank_account_name"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Nama Rekening"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="bank_account_number"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Nomor Rekening"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="bank_name"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Nama Bank"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="code"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                disabled
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Kode Rekanan"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="discount_percentage"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <NumericFormat
                allowNegative={false}
                customInput={TextField}
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputProps={{ inputMode: "numeric" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
                inputRef={ref}
                isAllowed={(values) => {
                  const { formattedValue, floatValue } = values;
                  return formattedValue === "" || floatValue <= 100;
                }}
                label="Persentase Diskon"
                onValueChange={(values) => {
                  onChange(values.floatValue);
                }}
                thousandSeparator=","
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="email"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Email"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="facsimile"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Fax"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="inactive_date"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <DatePicker
                format="dd-MM-yyyy"
                inputRef={ref}
                label="Tanggal nonaktif"
                onChange={onChange}
                slotProps={{
                  textField: {
                    error: !!error,
                    fullWidth: true,
                    helperText: error?.message,
                  },
                }}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="initial_balance"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <NumericFormat
                allowNegative={false}
                customInput={TextField}
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputProps={{ inputMode: "numeric" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">Rp</InputAdornment>
                  ),
                }}
                inputRef={ref}
                label="Saldo Awal"
                onValueChange={(values) => {
                  onChange(values.floatValue);
                }}
                thousandSeparator=","
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="name"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Nama Rekanan"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="nib"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <NumericFormat
                allowLeadingZeros={true}
                allowNegative={false}
                customInput={TextField}
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputProps={{ inputMode: "numeric" }}
                inputRef={ref}
                label="NIB"
                onValueChange={(values) => {
                  onChange(values.value);
                }}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="nik"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <NumericFormat
                allowLeadingZeros={true}
                allowNegative={false}
                customInput={TextField}
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputProps={{ inputMode: "numeric" }}
                inputRef={ref}
                label="NIK"
                onValueChange={(values) => {
                  onChange(values.value);
                }}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="npwp"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="NPWP"
                multiline
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="npwp_address"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Alamat NPWP"
                multiline
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="npwp_name"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Nama NPWP"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="office_address"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Alamat Kantor"
                multiline
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="partner_category"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <Autocomplete
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                loading={isLoadingAutocompletePartnerCategory}
                onChange={(_, newValue) => {
                  onChange(newValue);

                  if (newValue) {
                    fetchPartnerCode();
                  } else {
                    setValue("code", "");
                  }
                }}
                onClose={() => {
                  setIsOpenAutocompletePartnerCategory(false);
                }}
                onOpen={() => {
                  setIsOpenAutocompletePartnerCategory(true);
                }}
                open={isOpenAutocompletePartnerCategory}
                options={autocompletePartnerCategoryOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!error}
                    helperText={error?.message}
                    inputRef={ref}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {isLoadingAutocompletePartnerCategory ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                    label="Tipe Rekanan"
                  />
                )}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="phone_number"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <NumericFormat
                allowLeadingZeros={true}
                allowNegative={false}
                customInput={TextField}
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputProps={{ inputMode: "numeric" }}
                inputRef={ref}
                label="Nomor Telepon"
                onValueChange={(values) => {
                  onChange(values.value);
                }}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="pic"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Orang yang bertanggung jawab"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="skb"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="SKB"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="sppkp"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="SPPKP"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="sppkp_date"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <DatePicker
                format="dd-MM-yyyy"
                inputRef={ref}
                label="Tanggal SPPKP"
                onChange={onChange}
                slotProps={{
                  textField: {
                    error: !!error,
                    fullWidth: true,
                    helperText: error?.message,
                  },
                }}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="suket"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Suket"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="suket_active_date"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <DatePicker
                format="dd-MM-yyyy"
                inputRef={ref}
                label="Tanggal Aktif Suket"
                onChange={onChange}
                slotProps={{
                  textField: {
                    error: !!error,
                    fullWidth: true,
                    helperText: error?.message,
                  },
                }}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="suket_inactive_date"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <DatePicker
                format="dd-MM-yyyy"
                inputRef={ref}
                label="Tanggal Nonaktif Suket"
                onChange={onChange}
                slotProps={{
                  textField: {
                    error: !!error,
                    fullWidth: true,
                    helperText: error?.message,
                  },
                }}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="top"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="TOP"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="warehouse_address"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Alamat Gudang"
                multiline
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="flex-end">
            <LoadingButton
              endIcon={<AddIcon />}
              loading={isLoadingButtonState.buttonCreate}
              loadingPosition="end"
              type="submit"
              variant="contained"
            >
              Add
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
};

export default PartnerCreate;
