import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { GridActionsCellItem } from "@mui/x-data-grid";

export const getCompanyColumns = (
  dialogContext,
  handleDeleteCompany,
  handleUpdateCompany
) => [
  {
    field: "id",
    headerName: "ID Perusahaan",
    width: 80,
  },
  {
    field: "code",
    flex: 1,
    headerName: "Kode Perusahaan",
    minWidth: 200,
  },
  {
    field: "name",
    flex: 1,
    headerName: "Nama Perusahaan",
    minWidth: 200,
  },
  {
    field: "address",
    flex: 1,
    headerName: "Alamat Perusahaan",
    minWidth: 200,
  },
  {
    field: "tax_identification_number",
    flex: 1,
    headerName: "NPWP",
    minWidth: 200,
  },
  {
    field: "business_registration_number",
    flex: 1,
    headerName: "NIB",
    minWidth: 200,
  },
  {
    field: "notarial_deed_number",
    flex: 1,
    headerName: "Nomor Akta Notaris",
    minWidth: 200,
  },
  {
    field: "ward",
    flex: 1,
    headerName: "Kelurahan",
    minWidth: 200,
  },
  {
    field: "postal_code",
    flex: 1,
    headerName: "Kode Pos",
    minWidth: 200,
  },
  {
    field: "phone_number",
    flex: 1,
    headerName: "Nomor Telepon",
    minWidth: 200,
  },
  {
    field: "fax",
    flex: 1,
    headerName: "Fax",
    minWidth: 200,
  },
  {
    field: "email",
    flex: 1,
    headerName: "Email",
    minWidth: 200,
  },
  {
    field: "pic",
    flex: 1,
    headerName: "Penanggung Jawab",
    minWidth: 200,
  },
  {
    field: "pic_position",
    flex: 1,
    headerName: "Jabatan",
    minWidth: 200,
  },
  {
    field: "actions",
    headerName: "Aksi",
    minWidth: 150,
    type: "actions",
    getActions: (params) => [
      <GridActionsCellItem
        icon={<EditIcon color="info" />}
        label="Edit"
        onClick={() => {
          handleUpdateCompany(params.id);
        }}
      />,
      <GridActionsCellItem
        icon={<DeleteIcon color="error" />}
        label="Delete"
        onClick={() => {
          dialogContext.handleOpenDialog(
            () => handleDeleteCompany(params.id),
            "Are you sure want to delete?",
            "Delete Confirmation"
          );
        }}
      />,
    ],
  },
];
