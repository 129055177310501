import { yupResolver } from "@hookform/resolvers/yup";
import AddIcon from "@mui/icons-material/Add";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  CircularProgress,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { format } from "date-fns";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import AdminDataGridRow from "../../../../components/admin/AdminDataGridRow";
import { getFpdAccountColumns } from "../../../../constants/columns/admin/fpd/getFpdAccountColumns";
import { expenditureTypeOptions } from "../../../../constants/expenditureTypeOptions";
import SnackbarContext from "../../../../contexts/SnackbarContextProvider";
import { fpdSchema } from "../../../../schemas/fpdSchema";
import { getChartOfAccount } from "../../../../services/admin/chartOfAccount/getChartOfAccount";
import { getChartOfAccountByExpenditureType } from "../../../../services/admin/chartOfAccount/getChartOfAccountByExpenditureType";
import { getDivision } from "../../../../services/admin/division/getDivision";
import { createFpd } from "../../../../services/admin/fpd/createFpd";
import { getFpdNumber } from "../../../../services/admin/fpd/getFpdNumber";
import { generateSnackbarErrorMessage } from "../../../../utils/generateSnackbarErrorMessage";

const FpdCreate = () => {
  const { control, getValues, handleSubmit, reset, setValue } = useForm({
    defaultValues: {
      check_number: "",
      code: "",
      date: new Date(),
      destination_account: "",
      division: null,
      expenditure_type: null,
      recipient_name: "",
    },
    resolver: yupResolver(fpdSchema),
  });
  const [autocompleteCoaOptions, setAutocompleteCoaOptions] = useState([]);
  const [autocompleteDivisionOptions, setAutocompleteDivisionOptions] =
    useState([]);
  const [isLoadingAutocompleteDivision, setIsLoadingAutocompleteDivision] =
    useState(false);
  const [isLoadingButtonState, setIsLoadingButtonState] = useState({
    buttonCreate: false,
  });
  // eslint-disable-next-line
  const [isLoadingLinearProgress, setIsLoadingLinearProgress] =
    useOutletContext();
  const [isOpenAutocompleteDivision, setIsOpenAutocompleteDivision] =
    useState(false);
  const adminDataGridRowRef = useRef();
  const snackbarContext = useContext(SnackbarContext);

  const fetchCoa = async () => {
    try {
      setIsLoadingLinearProgress(true);

      let res;

      if (getValues("expenditure_type")) {
        res = await getChartOfAccountByExpenditureType(
          parseInt(getValues("expenditure_type").id)
        );
      } else {
        res = await getChartOfAccount();
      }

      if (res.status === 200) {
        setAutocompleteCoaOptions(res.payload);
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setIsLoadingLinearProgress(false);
    }
  };

  const fetchFpdNumber = async () => {
    try {
      setIsLoadingLinearProgress(true);

      const res = await getFpdNumber({
        date: format(getValues("date"), "yyyy-MM-dd HH:mm:ss"),
        division_id: getValues("division").id,
      });

      setValue("code", res.payload);
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setIsLoadingLinearProgress(false);
    }
  };

  const handleCreateFpd = async (data) => {
    try {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonCreate: true,
      }));
      setIsLoadingLinearProgress(true);

      data.date = format(data.date, "yyyy-MM-dd HH:mm:ss");
      data.expenditure_type = data.expenditure_type.id;
      data.fpd_detail = adminDataGridRowRef.current.rows;

      const res = await createFpd(data);

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 201) {
        reset();
        adminDataGridRowRef.current.setRows([]);
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonCreate: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  // autocomplete division
  useEffect(() => {
    setAutocompleteDivisionOptions([]);

    if (!isOpenAutocompleteDivision) {
      return undefined;
    }

    (async () => {
      try {
        setIsLoadingAutocompleteDivision(true);

        const res = await getDivision();

        if (res.status === 200) {
          setAutocompleteDivisionOptions(res.payload);
        }
      } catch (error) {
        snackbarContext.handleOpenSnackbar(
          generateSnackbarErrorMessage(error),
          "error"
        );
      } finally {
        setIsLoadingAutocompleteDivision(false);
      }
    })();

    return () => {};
    // eslint-disable-next-line
  }, [isOpenAutocompleteDivision]);

  return (
    <form onSubmit={handleSubmit(handleCreateFpd)}>
      <Grid container spacing={3}>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="code"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                disabled
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Nomor FPD"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="date"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <DatePicker
                format="dd-MM-yyyy"
                inputRef={ref}
                label="Tanggal FPD"
                onChange={(newValue) => {
                  onChange(newValue);

                  if (newValue && getValues("division")) {
                    fetchFpdNumber();
                  } else {
                    setValue("code", "");
                  }
                }}
                slotProps={{
                  textField: {
                    error: !!error,
                    fullWidth: true,
                    helperText: error?.message,
                  },
                }}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="division"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <Autocomplete
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                loading={isLoadingAutocompleteDivision}
                onChange={(_, newValue) => {
                  onChange(newValue);

                  if (getValues("date") && newValue) {
                    fetchFpdNumber();
                  } else {
                    setValue("code", "");
                  }
                }}
                onClose={() => {
                  setIsOpenAutocompleteDivision(false);
                }}
                onOpen={() => {
                  setIsOpenAutocompleteDivision(true);
                }}
                open={isOpenAutocompleteDivision}
                options={autocompleteDivisionOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!error}
                    helperText={error?.message}
                    inputRef={ref}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {isLoadingAutocompleteDivision ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                    label="Divisi"
                  />
                )}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="expenditure_type"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <Autocomplete
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(_, newValue) => {
                  onChange(newValue);
                  fetchCoa();
                }}
                options={expenditureTypeOptions}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!error}
                    helperText={error?.message}
                    inputRef={ref}
                    label="Jenis Pengeluaran"
                  />
                )}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="check_number"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Nomor Cek"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="destination_account"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Rekening Tujuan"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="recipient_name"
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <TextField
                error={!!error}
                fullWidth
                helperText={error?.message}
                inputRef={ref}
                label="Nama Penerima"
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <div
            onKeyDown={(e) => {
              e.key === "Enter" && e.preventDefault();
            }}
          >
            <AdminDataGridRow
              columns={getFpdAccountColumns}
              ref={adminDataGridRowRef}
              props={{ coa_code: autocompleteCoaOptions }}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="flex-end">
            <LoadingButton
              endIcon={<AddIcon />}
              loading={isLoadingButtonState.buttonCreate}
              loadingPosition="end"
              type="submit"
              variant="contained"
            >
              Add
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
};

export default FpdCreate;
