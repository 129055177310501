import { yupResolver } from "@hookform/resolvers/yup";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import { Grid, Paper, Stack, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { format, parse } from "date-fns";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import { useOutletContext, useParams } from "react-router-dom";
import SnackbarContext from "../../../../contexts/SnackbarContextProvider";
import { warehouseSchema } from "../../../../schemas/warehouseSchema";
import { getWarehouseById } from "../../../../services/admin/warehouse/getWarehouseById";
import { updateWarehouse } from "../../../../services/admin/warehouse/updateWarehouse";
import { generateSnackbarErrorMessage } from "../../../../utils/generateSnackbarErrorMessage";

const WarehouseDetail = () => {
  const { id } = useParams();
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      active_date: null,
      address: "",
      code: "",
      inactive_date: null,
      name: "",
      phone_number: "",
      pic: "",
    },
    resolver: yupResolver(warehouseSchema),
  });
  const [isLoadingButtonState, setIsLoadingButtonState] = useState({
    buttonUpdate: false,
  });
  // eslint-disable-next-line
  const [isLoadingLinearProgress, setIsLoadingLinearProgress] =
    useOutletContext();
  const snackbarContext = useContext(SnackbarContext);

  const fetchWarehouseById = async () => {
    try {
      setIsLoadingLinearProgress(true);

      const res = await getWarehouseById(id);

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 200) {
        setValue(
          "active_date",
          parse(res.payload.active_date, "yyyy-MM-dd", new Date())
        );
        setValue("address", res.payload.address);
        setValue("code", res.payload.code);
        if (res.payload.inactive_date) {
          setValue(
            "inactive_date",
            parse(res.payload.inactive_date, "yyyy-MM-dd", new Date())
          );
        }
        setValue("name", res.payload.name);
        setValue("phone_number", res.payload.phone_number);
        setValue("pic", res.payload.pic);
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setIsLoadingLinearProgress(false);
    }
  };

  const handleUpdateWarehouse = async (data) => {
    try {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonUpdate: true,
      }));
      setIsLoadingLinearProgress(true);

      data.id = parseInt(id);
      data.active_date = format(data.active_date, "yyyy-MM-dd HH:mm:ss");
      if (data.inactive_date) {
        data.inactive_date = format(data.inactive_date, "yyyy-MM-dd HH:mm:ss");
      }

      const res = await updateWarehouse(data);

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 200) {
        fetchWarehouseById();
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setIsLoadingButtonState((prevState) => ({
        ...prevState,
        buttonUpdate: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  useEffect(() => {
    fetchWarehouseById();

    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <Paper sx={{ p: 3 }}>
      <form onSubmit={handleSubmit(handleUpdateWarehouse)}>
        <Grid container spacing={3}>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="code"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <TextField
                  disabled
                  error={!!error}
                  fullWidth
                  helperText={error?.message}
                  inputRef={ref}
                  label="Kode Gudang"
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="name"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <TextField
                  error={!!error}
                  fullWidth
                  helperText={error?.message}
                  inputRef={ref}
                  label="Nama Gudang"
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="pic"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <TextField
                  error={!!error}
                  fullWidth
                  helperText={error?.message}
                  inputRef={ref}
                  label="PIC"
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="phone_number"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <NumericFormat
                  allowLeadingZeros={true}
                  allowNegative={false}
                  customInput={TextField}
                  error={!!error}
                  fullWidth
                  helperText={error?.message}
                  inputProps={{ inputMode: "numeric" }}
                  inputRef={ref}
                  label="Nomor Telepon"
                  onValueChange={(values) => {
                    onChange(values.value);
                  }}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="address"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <TextField
                  error={!!error}
                  fullWidth
                  helperText={error?.message}
                  inputRef={ref}
                  label="Alamat Gudang"
                  multiline
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="active_date"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <DatePicker
                  format="dd-MM-yyyy"
                  inputRef={ref}
                  label="Tanggal Aktif"
                  onChange={onChange}
                  slotProps={{
                    textField: {
                      error: !!error,
                      fullWidth: true,
                      helperText: error?.message,
                    },
                  }}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="inactive_date"
              render={({
                field: { onChange, ref, value },
                fieldState: { error },
              }) => (
                <DatePicker
                  format="dd-MM-yyyy"
                  inputRef={ref}
                  label="Tanggal Nonaktif"
                  onChange={onChange}
                  slotProps={{
                    textField: {
                      error: !!error,
                      fullWidth: true,
                      helperText: error?.message,
                    },
                  }}
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-end">
              <LoadingButton
                endIcon={<SaveIcon />}
                loading={isLoadingButtonState.buttonUpdate}
                loadingPosition="end"
                type="submit"
                variant="contained"
              >
                Save
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default WarehouseDetail;
