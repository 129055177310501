import * as yup from "yup";

export const productSchema = yup.object().shape({
  active_date: yup.date().required("Mohon mengisi tanggal aktif."),
  code: yup.string().required("Mohon mengisi kode barang."),
  conversion_big: yup.number().required("Mohon mengisi konversi besar."),
  conversion_medium: yup.number().required("Mohon mengisi konversi menengah."),
  conversion_small: yup.number().required("Mohon mengisi konversi kecil."),
  default_discount_percentage: yup.number().notRequired(),
  expiry_date: yup.date().notRequired(),
  hpp: yup.number().required("Mohon mengisi hpp."),
  inactive_date: yup.date().notRequired(),
  initial_stock_big: yup.number().required("Mohon mengisi stok awal besar."),
  initial_stock_medium: yup
    .number()
    .required("Mohon mengisi stok awal menengah."),
  initial_stock_small: yup.number().required("Mohon mengisi stok awal kecil."),
  inventory_value: yup.number().required("Mohon mengisi nilai persediaan."),
  maximum_stock: yup.number().required("Mohon mengisi stok maksimum."),
  minimum_stock: yup.number().required("Mohon mengisi stok minimum."),
  name: yup.string().required("Mohon mengisi nama barang."),
  price_big: yup.number().required("Mohon mengisi harga satuan besar."),
  price_medium: yup.number().required("Mohon mengisi harga satuan menengah."),
  price_small: yup.number().required("Mohon mengisi harga satuan kecil."),
  product_category: yup.object().required("Mohon mengisi kategori produk."),
  purchase_discount_percentage: yup.number().notRequired(),
  purchase_price: yup.number().required("Mohon mengisi harga beli."),
  sale_discount_percentage: yup.number().notRequired(),
  sale_price: yup.number().required("Mohon mengisi harga jual."),
  stock_as_of_date: yup.number().required("Mohon mengisi stok per tanggal."),
  unit_big: yup.object().required("Mohon mengisi satuan besar."),
  unit_medium: yup.object().required("Mohon mengisi satuan menengah."),
  unit_small: yup.object().required("Mohon mengisi satuan kecil."),
  warehouse: yup.object().required("Mohon mengisi gudang."),
});
