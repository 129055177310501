import * as yup from "yup";

export const partnerSchema = yup.object().shape({
  active_date: yup.date().required("Mohon mengisi tanggal aktif."),
  balance_as_of_date: yup.number().required("Mohon mengisi saldo per tanggal."),
  balance_limit: yup.number().required("Mohon mengisi limit saldo."),
  bank_account_name: yup.string().required("Mohon mengisi nama rekening."),
  bank_account_number: yup.string().required("Mohon mengisi nomor rekening."),
  bank_name: yup.string().required("Mohon mengisi nama bank."),
  code: yup.string().required("Mohon mengisi kode."),
  discount_percentage: yup
    .number()
    .required("Mohon mengisi persentase diskon."),
  email: yup
    .string()
    .email("Format email salah.")
    .required("Mohon mengisi email."),
  facsimile: yup.string().required("Mohon mengisi fax."),
  inactive_date: yup.date().notRequired(),
  initial_balance: yup.number().required("Mohon mengisi saldo awal."),
  name: yup.string().required("Mohon mengisi nama."),
  nib: yup.string().required("Mohon mengisi nib."),
  nik: yup.string().required("Mohon mengisi nik."),
  npwp: yup.string().required("Mohon mengisi npwp."),
  npwp_address: yup.string().required("Mohon mengisi alamat npwp."),
  npwp_name: yup.string().required("Mohon mengisi nama npwp."),
  office_address: yup.string().required("Mohon mengisi alamat kantor."),
  partner_category: yup.object().required("Mohon mengisi tipe rekanan."),
  phone_number: yup.string().required("Mohon mengisi nomor telepon."),
  pic: yup.string().required("Mohon mengisi pic."),
  skb: yup.string().required("Mohon mengisi skb."),
  sppkp: yup.string().required("Mohon mengisi sppkp."),
  sppkp_date: yup.string().required("Mohon mengisi tanggal sppkp."),
  suket: yup.string().required("Mohon mengisi suket."),
  suket_active_date: yup.date().required("Mohon mengisi tanggal aktif suket."),
  suket_inactive_date: yup
    .date()
    .required("Mohon mengisi tanggal nonaktif suket."),
  top: yup.string().required("Mohon mengisi top."),
  warehouse_address: yup.string().required("Mohon mengisi alamat gudang."),
});
