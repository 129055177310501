import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import InfoIcon from "@mui/icons-material/Info";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { format } from "date-fns";

export const getPurchaseOrderColumns = (
  dialogContext,
  handleDeleteFpd,
  handleExportFpdPdf,
  handleGetFpdDetails,
  handleUpdateFpd
) => [
  {
    field: "id",
    headerName: "ID",
    width: 80,
  },
  {
    field: "date",
    flex: 1,
    headerName: "Tanggal",
    minWidth: 200,
    type: "date",
    valueFormatter: (value) => {
      return value ? format(value, "dd-MM-yyyy") : null;
    },
  },
  {
    field: "code",
    flex: 1,
    headerName: "Nomor PO",
    minWidth: 200,
  },
  {
    field: "partner_name",
    flex: 1,
    headerName: "Nama Divisi",
    minWidth: 200,
    valueGetter: (_, row) => {
      return row?.partner?.name ?? "";
    },
  },
  {
    field: "down_payment",
    flex: 1,
    headerName: "Uang Muka",
    minWidth: 200,
    type: "number",
    valueFormatter: (value) => {
      return new Intl.NumberFormat("id-ID", {
        currency: "IDR",
        style: "currency",
      }).format(value);
    },
  },
  {
    field: "dpp",
    flex: 1,
    headerName: "DPP",
    minWidth: 200,
    type: "number",
    valueFormatter: (value) => {
      return new Intl.NumberFormat("id-ID").format(value);
    },
  },
  {
    field: "pph22",
    flex: 1,
    headerName: "PPH22",
    minWidth: 200,
    type: "number",
    valueFormatter: (value) => {
      return new Intl.NumberFormat("id-ID").format(value);
    },
  },
  {
    field: "ppn",
    flex: 1,
    headerName: "PPN (%)",
    minWidth: 200,
    type: "number",
    valueFormatter: (value) => {
      return new Intl.NumberFormat("id-ID").format(value);
    },
  },
  {
    field: "discount_percentage",
    flex: 1,
    headerName: "Persentase Diskon (%)",
    minWidth: 200,
    type: "number",
    valueFormatter: (value) => {
      return new Intl.NumberFormat("id-ID").format(value);
    },
  },
  {
    field: "invoice_discount",
    flex: 1,
    headerName: "Diskon Invoice",
    minWidth: 200,
    type: "number",
    valueFormatter: (value) => {
      return new Intl.NumberFormat("id-ID", {
        currency: "IDR",
        style: "currency",
      }).format(value);
    },
  },
  {
    field: "actions",
    headerName: "Aksi",
    minWidth: 150,
    type: "actions",
    getActions: (params) => [
      <GridActionsCellItem
        icon={<InfoIcon color="action" />}
        label="Detail"
        onClick={() => {
          handleGetFpdDetails(params.row.purchase_order_detail);
        }}
      />,
      <GridActionsCellItem
        icon={<EditIcon color="info" />}
        label="Edit"
        onClick={() => {
          handleUpdateFpd(params.row.id);
        }}
      />,
      <GridActionsCellItem
        icon={<DeleteIcon color="error" />}
        label="Delete"
        onClick={() => {
          dialogContext.handleOpenDialog(
            () => handleDeleteFpd(params.id),
            "Are you sure want to delete?",
            "Delete Confirmation"
          );
        }}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<FileDownloadIcon color="warning" />}
        label="Export"
        onClick={() => {
          handleExportFpdPdf(params.row.id, params.row.code);
        }}
        showInMenu
      />,
    ],
  },
];
