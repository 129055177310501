import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { format } from "date-fns";

export const getWarehouseColumns = (
  dialogContext,
  handleDeleteWarehouse,
  handleUpdateWarehouse
) => [
  {
    field: "id",
    headerName: "ID",
    width: 80,
  },
  {
    field: "code",
    headerName: "Kode",
    width: 200,
  },
  {
    field: "name",
    headerName: "Nama Gudang",
    width: 200,
  },
  {
    field: "pic",
    headerName: "PIC",
    width: 200,
  },
  {
    field: "phone_number",
    headerName: "Nomor Telepon",
    width: 200,
  },
  {
    field: "address",
    headerName: "Alamat Gudang",
    width: 200,
  },
  {
    field: "active_date",
    flex: 1,
    headerName: "Tanggal Aktif",
    minWidth: 200,
    type: "date",
    valueFormatter: (value) => {
      return value ? format(value, "dd-MM-yyyy") : null;
    },
  },
  {
    field: "inactive_date",
    flex: 1,
    headerName: "Tanggal Nonaktif",
    minWidth: 200,
    type: "date",
    valueFormatter: (value) => {
      return value ? format(value, "dd-MM-yyyy") : null;
    },
  },
  {
    field: "actions",
    headerName: "Aksi",
    minWidth: 150,
    type: "actions",
    getActions: (params) => [
      <GridActionsCellItem
        icon={<EditIcon color="info" />}
        label="Edit"
        onClick={() => {
          handleUpdateWarehouse(params.row.id);
        }}
      />,
      <GridActionsCellItem
        icon={<DeleteIcon color="error" />}
        label="Delete"
        onClick={() => {
          dialogContext.handleOpenDialog(
            () => handleDeleteWarehouse(params.id),
            "Are you sure want to delete?",
            "Delete Confirmation"
          );
        }}
      />,
    ],
  },
];
