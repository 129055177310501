import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import InfoIcon from "@mui/icons-material/Info";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { format } from "date-fns";
import { expenditureTypeOptions } from "../../../expenditureTypeOptions";

export const getFpdColumns = (
  dialogContext,
  handleDeleteFpd,
  handleExportFpdPdf,
  handleGetFpdDetails,
  handleUpdateFpd
) => [
  {
    field: "id",
    headerName: "ID",
    width: 80,
  },
  {
    field: "date",
    flex: 1,
    headerName: "Tanggal",
    minWidth: 200,
    type: "date",
    valueFormatter: (value) => {
      return value ? format(value, "dd-MM-yyyy") : null;
    },
  },
  {
    field: "payment_date",
    flex: 1,
    headerName: "Tanggal Bayar",
    minWidth: 200,
    type: "date",
    valueFormatter: (value) => {
      return value ? format(value, "dd-MM-yyyy") : null;
    },
  },
  {
    field: "code",
    flex: 1,
    headerName: "Nomor FPD",
    minWidth: 200,
  },
  {
    field: "bank_cash_header_voucher_number",
    flex: 1,
    headerName: "Nomor Voucher",
    minWidth: 200,
  },
  {
    field: "division_name",
    flex: 1,
    headerName: "Nama Divisi",
    minWidth: 200,
    valueGetter: (_, row) => {
      return row?.division?.name ?? "";
    },
  },
  {
    field: "expenditure_type",
    flex: 1,
    headerName: "Jenis Pengeluaran",
    minWidth: 200,
    valueGetter: (_, row) => {
      return (
        expenditureTypeOptions.find((e) => e.id === row.expenditure_type)
          ?.name ?? ""
      );
    },
  },
  {
    field: "check_number",
    flex: 1,
    headerName: "Nomor Cek",
    minWidth: 200,
  },
  {
    field: "destination_account",
    flex: 1,
    headerName: "Rekening Tujuan",
    minWidth: 200,
  },
  {
    field: "recipient_name",
    flex: 1,
    headerName: "Nama Penerima",
    minWidth: 200,
  },
  {
    field: "is_approved",
    flex: 1,
    headerName: "Status Persetujuan",
    minWidth: 200,
    type: "boolean",
  },
  {
    field: "fpd_detail_total",
    flex: 1,
    headerName: "Total",
    minWidth: 200,
    type: "number",
    valueFormatter: (value) => {
      return new Intl.NumberFormat("id-ID", {
        currency: "IDR",
        style: "currency",
      }).format(value);
    },
  },
  {
    field: "actions",
    headerName: "Aksi",
    minWidth: 150,
    type: "actions",
    getActions: (params) => [
      <GridActionsCellItem
        icon={<InfoIcon color="action" />}
        label="Detail"
        onClick={() => {
          handleGetFpdDetails(params.row.fpd_detail);
        }}
      />,
      <GridActionsCellItem
        icon={<EditIcon color="info" />}
        label="Edit"
        onClick={() => {
          handleUpdateFpd(params.row.id);
        }}
      />,
      <GridActionsCellItem
        icon={<DeleteIcon color="error" />}
        label="Delete"
        onClick={() => {
          dialogContext.handleOpenDialog(
            () => handleDeleteFpd(params.id),
            "Are you sure want to delete?",
            "Delete Confirmation"
          );
        }}
        showInMenu
      />,
      <GridActionsCellItem
        icon={<FileDownloadIcon color="warning" />}
        label="Export"
        onClick={() => {
          handleExportFpdPdf(params.row.id, params.row.code);
        }}
        showInMenu
      />,
    ],
  },
];
