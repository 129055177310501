import * as yup from "yup";

export const fpdSchema = yup.object().shape({
  check_number: yup.string(),
  code: yup.string(),
  date: yup.date().required("Mohon mengisi tanggal."),
  destination_account: yup.string(),
  division: yup.object().required("Mohon memilih divisi."),
  expenditure_type: yup.object().required("Mohon memilih jenis pengeluaran."),
  recipient_name: yup.string(),
});
