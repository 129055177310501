import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import AdminDataGridPro from "../../../../components/admin/AdminDataGridPro/AdminDataGridPro";
import { getProductCategoryColumns } from "../../../../constants/columns/admin/productCategory/getProductCategoryColumns";
import DialogContext from "../../../../contexts/DialogContextProvider";
import SnackbarContext from "../../../../contexts/SnackbarContextProvider";
import { deleteProductCategory } from "../../../../services/admin/productCategory/deleteProductCategory";
import { getProductCategory } from "../../../../services/admin/productCategory/getProductCategory";
import { generateSnackbarErrorMessage } from "../../../../utils/generateSnackbarErrorMessage";

const ProductCategoryList = () => {
  const [dataGridState, setDataGridState] = useState({
    isLoading: false,
    rows: [],
  });
  // eslint-disable-next-line
  const [isLoadingLinearProgress, setIsLoadingLinearProgress] =
    useOutletContext();
  const dialogContext = useContext(DialogContext);
  const navigate = useNavigate();
  const snackbarContext = useContext(SnackbarContext);

  const fetchProductCategory = async () => {
    try {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      setIsLoadingLinearProgress(true);

      const res = await getProductCategory();

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      setDataGridState((prevState) => ({
        ...prevState,
        rows: res.payload,
      }));
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };
  const handleDeleteProductCategory = async (id) => {
    try {
      setIsLoadingLinearProgress(true);

      const res = await deleteProductCategory(id);

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      fetchProductCategory();
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setIsLoadingLinearProgress(false);
    }
  };

  const handleUpdateProductCategory = (id) => {
    navigate(`/admin/product_category/${id}`);
  };

  useEffect(() => {
    fetchProductCategory();

    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <AdminDataGridPro
      columns={getProductCategoryColumns(
        dialogContext,
        handleDeleteProductCategory,
        handleUpdateProductCategory
      )}
      dataGridState={dataGridState}
    />
  );
};

export default ProductCategoryList;
