import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { format } from "date-fns";

export const getProductColumns = (
  dialogContext,
  handleDeleteProduct,
  handleUpdateProduct
) => [
  {
    field: "id",
    headerName: "ID",
    width: 80,
  },
  {
    field: "code",
    headerName: "Code",
    width: 200,
  },
  {
    field: "name",
    headerName: "Nama",
    width: 200,
  },
  {
    field: "product_category_name",
    flex: 1,
    headerName: "Kategori Barang",
    minWidth: 200,
    valueGetter: (_, row) => {
      return row?.product_category?.name ?? "";
    },
  },
  {
    field: "active_date",
    flex: 1,
    headerName: "Tanggal Aktif",
    minWidth: 200,
    type: "date",
    valueFormatter: (value) => {
      return value ? format(value, "dd-MM-yyyy") : null;
    },
  },
  {
    field: "inactive_date",
    flex: 1,
    headerName: "Tanggal  Nonaktif",
    minWidth: 200,
    type: "date",
    valueFormatter: (value) => {
      return value ? format(value, "dd-MM-yyyy") : null;
    },
  },
  {
    field: "unit_small_name",
    headerName: "Satuan Kecil",
    width: 200,
    valueGetter: (_, row) => {
      return row?.unit_small?.name ?? "";
    },
  },
  {
    field: "price_small",
    flex: 1,
    headerName: "Harga Satuan Kecil",
    minWidth: 200,
    type: "number",
    valueFormatter: (value) => {
      return new Intl.NumberFormat("id-ID", {
        currency: "IDR",
        style: "currency",
      }).format(value);
    },
  },
  {
    field: "unit_medium_name",
    headerName: "Satuan Menengah",
    width: 200,
    valueGetter: (_, row) => {
      return row?.unit_medium?.name ?? "";
    },
  },
  {
    field: "price_medium",
    flex: 1,
    headerName: "Harga Satuan Menengah",
    minWidth: 200,
    type: "number",
    valueFormatter: (value) => {
      return new Intl.NumberFormat("id-ID", {
        currency: "IDR",
        style: "currency",
      }).format(value);
    },
  },
  {
    field: "unit_big_name",
    headerName: "Satuan Besar",
    width: 200,
    valueGetter: (_, row) => {
      return row?.unit_big?.name ?? "";
    },
  },
  {
    field: "price_big",
    flex: 1,
    headerName: "Harga Satuan Besar",
    minWidth: 200,
    type: "number",
    valueFormatter: (value) => {
      return new Intl.NumberFormat("id-ID", {
        currency: "IDR",
        style: "currency",
      }).format(value);
    },
  },
  {
    field: "conversion_big",
    flex: 1,
    headerName: "Konversi Besar",
    minWidth: 200,
    type: "number",
    valueFormatter: (value) => {
      return new Intl.NumberFormat("id-ID").format(value);
    },
  },
  {
    field: "conversion_medium",
    flex: 1,
    headerName: "Konversi Menengah",
    minWidth: 200,
    type: "number",
    valueFormatter: (value) => {
      return new Intl.NumberFormat("id-ID").format(value);
    },
  },
  {
    field: "conversion_small",
    flex: 1,
    headerName: "Konversi Kecil",
    minWidth: 200,
    type: "number",
    valueFormatter: (value) => {
      return new Intl.NumberFormat("id-ID").format(value);
    },
  },
  {
    field: "hpp",
    flex: 1,
    headerName: "HPP",
    minWidth: 200,
    type: "number",
    valueFormatter: (value) => {
      return new Intl.NumberFormat("id-ID").format(value);
    },
  },
  {
    field: "initial_stock_big",
    flex: 1,
    headerName: "Stok Awal Besar",
    minWidth: 200,
    type: "number",
    valueFormatter: (value) => {
      return new Intl.NumberFormat("id-ID").format(value);
    },
  },
  {
    field: "initial_stock_medium",
    flex: 1,
    headerName: "Stok Awal Menengah",
    minWidth: 200,
    type: "number",
    valueFormatter: (value) => {
      return new Intl.NumberFormat("id-ID").format(value);
    },
  },
  {
    field: "initial_stock_small",
    flex: 1,
    headerName: "Stok Awal Kecil",
    minWidth: 200,
    type: "number",
    valueFormatter: (value) => {
      return new Intl.NumberFormat("id-ID").format(value);
    },
  },
  {
    field: "inventory_value",
    flex: 1,
    headerName: "Nilai Persediaan",
    minWidth: 200,
    type: "number",
    valueFormatter: (value) => {
      return new Intl.NumberFormat("id-ID").format(value);
    },
  },
  {
    field: "maximum_stock",
    flex: 1,
    headerName: "Stok Maksimum",
    minWidth: 200,
    type: "number",
    valueFormatter: (value) => {
      return new Intl.NumberFormat("id-ID").format(value);
    },
  },
  {
    field: "minimum_stock",
    flex: 1,
    headerName: "Stok Minimum",
    minWidth: 200,
    type: "number",
    valueFormatter: (value) => {
      return new Intl.NumberFormat("id-ID").format(value);
    },
  },
  {
    field: "stock_as_of_date",
    flex: 1,
    headerName: "Stok per Tanggal",
    minWidth: 200,
    type: "number",
    valueFormatter: (value) => {
      return new Intl.NumberFormat("id-ID").format(value);
    },
  },
  {
    field: "purchase_price",
    flex: 1,
    headerName: "Harga Beli",
    minWidth: 200,
    type: "number",
    valueFormatter: (value) => {
      return new Intl.NumberFormat("id-ID", {
        currency: "IDR",
        style: "currency",
      }).format(value);
    },
  },
  {
    field: "sale_price",
    flex: 1,
    headerName: "Harga Jual",
    minWidth: 200,
    type: "number",
    valueFormatter: (value) => {
      return new Intl.NumberFormat("id-ID", {
        currency: "IDR",
        style: "currency",
      }).format(value);
    },
  },
  {
    field: "default_discount_percentage",
    flex: 1,
    headerName: "Default Diskon (%)",
    minWidth: 200,
    type: "number",
    valueFormatter: (value) => {
      return new Intl.NumberFormat("id-ID").format(value);
    },
  },
  {
    field: "purchase_discount_percentage",
    flex: 1,
    headerName: "Persentase Diskon Beli (%)",
    minWidth: 200,
    type: "number",
    valueFormatter: (value) => {
      return new Intl.NumberFormat("id-ID").format(value);
    },
  },
  {
    field: "sale_discount_percentage",
    flex: 1,
    headerName: "Persentase Diskon Jual (%)",
    minWidth: 200,
    type: "number",
    valueFormatter: (value) => {
      return new Intl.NumberFormat("id-ID").format(value);
    },
  },
  {
    field: "warehouse_name",
    flex: 1,
    headerName: "Gudang",
    minWidth: 200,
    valueGetter: (_, row) => {
      return row?.warehouse?.name ?? "";
    },
  },
  {
    field: "actions",
    headerName: "Aksi",
    minWidth: 150,
    type: "actions",
    getActions: (params) => [
      <GridActionsCellItem
        icon={<EditIcon color="info" />}
        label="Edit"
        onClick={() => {
          handleUpdateProduct(params.row.id);
        }}
      />,
      <GridActionsCellItem
        icon={<DeleteIcon color="error" />}
        label="Delete"
        onClick={() => {
          dialogContext.handleOpenDialog(
            () => handleDeleteProduct(params.id),
            "Are you sure want to delete?",
            "Delete Confirmation"
          );
        }}
      />,
    ],
  },
];
