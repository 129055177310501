import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import AdminDataGridPro from "../../../../components/admin/AdminDataGridPro/AdminDataGridPro";
import { getWarehouseColumns } from "../../../../constants/columns/admin/warehouse/getWarehouseColumns";
import DialogContext from "../../../../contexts/DialogContextProvider";
import SnackbarContext from "../../../../contexts/SnackbarContextProvider";
import { deleteWarehouse } from "../../../../services/admin/warehouse/deleteWarehouse";
import { getWarehouse } from "../../../../services/admin/warehouse/getWarehouse";
import { generateSnackbarErrorMessage } from "../../../../utils/generateSnackbarErrorMessage";

const WarehouseList = () => {
  const [dataGridState, setDataGridState] = useState({
    isLoading: false,
    rows: [],
  });
  // eslint-disable-next-line
  const [isLoadingLinearProgress, setIsLoadingLinearProgress] =
    useOutletContext();
  const dialogContext = useContext(DialogContext);
  const navigate = useNavigate();
  const snackbarContext = useContext(SnackbarContext);

  const fetchWarehouse = async () => {
    try {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      setIsLoadingLinearProgress(true);

      const res = await getWarehouse();

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      setDataGridState((prevState) => ({
        ...prevState,
        rows: res.payload,
      }));
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };
  const handleDeleteWarehouse = async (id) => {
    try {
      setIsLoadingLinearProgress(true);

      const res = await deleteWarehouse(id);

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      fetchWarehouse();
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setIsLoadingLinearProgress(false);
    }
  };

  const handleUpdateWarehouse = (id) => {
    navigate(`/admin/warehouse/${id}`);
  };

  useEffect(() => {
    fetchWarehouse();

    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <AdminDataGridPro
      columns={getWarehouseColumns(
        dialogContext,
        handleDeleteWarehouse,
        handleUpdateWarehouse
      )}
      dataGridState={dataGridState}
    />
  );
};

export default WarehouseList;
