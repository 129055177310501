import Lottie from "lottie-react";
import React from "react";
import styles from "./Empty.module.scss";
import emptyJSON from "./emptyJSON.json";

const Empty = () => {
  return (
    <div className={styles["empty__container"]}>
      <Lottie animationData={emptyJSON} loop={true} />
      <p className={styles["empty__text"]}>No Data</p>
    </div>
  );
};

export default Empty;
