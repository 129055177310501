import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import AdminDataGridPro from "../../../../components/admin/AdminDataGridPro/AdminDataGridPro";
import { getManageUserRoleColumns } from "../../../../constants/columns/admin/getManageUserRoleColumns";
import DialogContext from "../../../../contexts/DialogContextProvider";
import SnackbarContext from "../../../../contexts/SnackbarContextProvider";
import { deleteUserRole } from "../../../../services/admin/manageUserRole/deleteUserRole";
import { getUserRole } from "../../../../services/admin/manageUserRole/getUserRole";
import { generateSnackbarErrorMessage } from "../../../../utils/generateSnackbarErrorMessage";

const ManageUserRoleList = () => {
  const [dataGridState, setDataGridState] = useState({
    isLoading: false,
    rows: [],
  });
  // eslint-disable-next-line
  const [isLoadingLinearProgress, setIsLoadingLinearProgress] =
    useOutletContext();
  const dialogContext = useContext(DialogContext);
  const navigate = useNavigate();
  const snackbarContext = useContext(SnackbarContext);

  const fetchManageUserRole = async () => {
    try {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      setIsLoadingLinearProgress(true);

      const res = await getUserRole();

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 200) {
        setDataGridState((prevState) => ({
          ...prevState,
          rows: res.payload,
        }));
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setDataGridState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      setIsLoadingLinearProgress(false);
    }
  };

  const handleDeleteManageUserRole = async (id) => {
    try {
      setIsLoadingLinearProgress(true);

      const res = await deleteUserRole(id);

      snackbarContext.handleOpenSnackbar(res.message, res.status);

      if (res.status === 200) {
        fetchManageUserRole();
      }
    } catch (error) {
      snackbarContext.handleOpenSnackbar(
        generateSnackbarErrorMessage(error),
        "error"
      );
    } finally {
      setIsLoadingLinearProgress(false);
    }
  };

  const handleUpdateManageUserRole = (id) => {
    navigate(`/admin/manage_user_role/${id}`);
  };

  useEffect(() => {
    fetchManageUserRole();

    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <AdminDataGridPro
      columns={getManageUserRoleColumns(
        dialogContext,
        handleDeleteManageUserRole,
        handleUpdateManageUserRole
      )}
      dataGridState={dataGridState}
    />
  );
};

export default ManageUserRoleList;
