import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import { GridActionsCellItem } from "@mui/x-data-grid";

export const getCalculateHppColumns = (
  dialogContext,
  handleDeleteCalculateHpp,
  handleGetCalculateHppDetails,
  handleUpdateCalculateHpp
) => [
  {
    field: "id",
    headerName: "No",
    width: 80,
  },
  {
    field: "product_name",
    flex: 1,
    headerName: "Nama Produk",
    minWidth: 200,
  },
  {
    field: "yield",
    flex: 1,
    headerName: "Rendeman",
    minWidth: 200,
    valueGetter: (_, row) => {
      return `${row.yield}%`;
    },
  },
  {
    field: "packaging",
    flex: 1,
    headerName: "Kemasan",
    minWidth: 200,
  },
  {
    field: "formula_code",
    flex: 1,
    headerName: "Kode Formula",
    minWidth: 200,
  },
  {
    field: "product_code",
    flex: 1,
    headerName: "Kode Produk",
    minWidth: 200,
  },
  {
    field: "trial_date",
    flex: 1,
    headerName: "Tanggal Trial",
    minWidth: 200,
  },
  {
    field: "status",
    flex: 1,
    headerName: "Status",
    minWidth: 200,
  },
  {
    field: "revision",
    flex: 1,
    headerName: "Revisi ke-",
    minWidth: 200,
  },
  {
    field: "actions",
    headerName: "Aksi",
    minWidth: 150,
    type: "actions",
    getActions: (params) => [
      <GridActionsCellItem
        icon={<InfoIcon color="action" />}
        label="Detail"
        onClick={() => {
          handleGetCalculateHppDetails(params.id);
        }}
      />,
      <GridActionsCellItem
        icon={<EditIcon color="info" />}
        label="Edit"
        onClick={() => {
          handleUpdateCalculateHpp(params.id);
        }}
      />,
      <GridActionsCellItem
        icon={<DeleteIcon color="error" />}
        label="Delete"
        onClick={() => {
          dialogContext.handleOpenDialog(
            () => handleDeleteCalculateHpp(params.id),
            "Are you sure want to delete?",
            "Delete Confirmation"
          );
        }}
      />,
    ],
  },
];
