import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { GridActionsCellItem } from "@mui/x-data-grid";

export const getProductCategoryColumns = (
  dialogContext,
  handleDeleteProductCategory,
  handleUpdateProductCategory
) => [
  {
    field: "id",
    headerName: "ID",
    width: 80,
  },
  {
    field: "code",
    flex: 1,
    headerName: "Kode Kategori Barang",
    minWidth: 200,
  },
  {
    field: "name",
    flex: 1,
    headerName: "Nama Kategori Barang",
    minWidth: 200,
  },
  {
    field: "actions",
    headerName: "Aksi",
    minWidth: 150,
    type: "actions",
    getActions: (params) => [
      <GridActionsCellItem
        icon={<EditIcon color="info" />}
        label="Edit"
        onClick={() => {
          handleUpdateProductCategory(params.row.id);
        }}
      />,
      <GridActionsCellItem
        icon={<DeleteIcon color="error" />}
        label="Delete"
        onClick={() => {
          dialogContext.handleOpenDialog(
            () => handleDeleteProductCategory(params.id),
            "Are you sure want to delete?",
            "Delete Confirmation"
          );
        }}
      />,
    ],
  },
];
